.player {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 51%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
