#product_swiper_container .swiper-button-next {
  right: 12px;
  width: 20px;
}

#product_swiper_container .swiper-button-next::after {
  font-size: 28px;
  color: #d1d5db;
}

#product_swiper_container .swiper-button-prev {
  left: 12px;
}

#product_swiper_container .swiper-button-prev::after {
  font-size: 28px;
  color: #d1d5db;
}
