// card sizing aspect-ratio mixin
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .panelContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.viewOldCatalog {
  width: 100%;
  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    p {
      margin-bottom: 20px;
    }
  }
}
.closeCollection {
  cursor: pointer;
  margin-left: auto;
  span {
    font-size: 1.3rem;
    margin: 2px 0 0 7px;
    font-weight: 600;
  }
}
.overScroll {
  box-shadow: none;
  visibility: hidden;
  padding: 10px 0 30px 0;
  width: 50px;
  position: relative !important;
}
.gridPadding {
  padding-right: 35px;
}
.catalogRibbon {
  padding: 0 0 35px 35px;
}
.ribbonInfo {
  display: flex;
  margin: 20px 0;
  margin-right: 35px;
  margin-left: 5px;
  h5 {
    font-weight: 300;
    color: #6b6b6b;
  }
  .more {
    display: flex;
    margin-left: auto;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 8px !important;
      cursor: pointer;
    }
  }
}
.ribbonCards {
  width: 100%;
  display: flex;
  padding-bottom: 0;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  > div {
    flex: 0 0 auto;
    margin: 10px 20px 20px 20px;
    background: #fff;
    box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
    border-radius: 2px;
    z-index: 100;
    transition: 0.5s cubic-bezier(0.4, 0.4, 0, 1);
    cursor: pointer;
    background-size: cover;
    position: relative;
    &.tile {
      width: 320px;
      height: 180px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center right;
      span {
        font-size: 2.25rem;
        font-weight: 600;
        letter-spacing: 1px;
        z-index: 2;
        text-shadow: 0px 1px 2px #686868;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(81, 102, 116, 0.3);
        z-index: 1;
      }
    }
    &.badSearch {
      background: transparent;
      box-shadow: none;
      margin: 50px auto;
      text-align: center;
      h4 {
        line-height: 50px;
        color: #333333;
        span {
          background: linear-gradient(115deg, #e687ff, #ff5689);
          color: #e687ff;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.ribbonItem {
  .selectCheckbox {
    display: none;
  }
  &:hover {
    .selectCheckbox {
      display: initial;
    }
  }
}
.portrait {
  width: 230.7px;
  height: 320px;
  background-size: cover;
  box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
  box-shadow: 0 15px 20px -15px rgba(125, 124, 124, 0.3),
    0 55px 50px -35px rgba(156, 156, 156, 0.3),
    0 85px 60px -25px rgba(255, 255, 255, 0.1);
}
.landscape {
  width: 320px;
  height: 230.7px;
  background-size: cover;
  box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
  box-shadow: 0 15px 20px -15px rgba(125, 124, 124, 0.3),
    0 55px 50px -35px rgba(156, 156, 156, 0.3),
    0 85px 60px -25px rgba(255, 255, 255, 0.1);
}
@media (max-width: 580px) {
  .gridPadding {
    padding-right: 0;
  }
  .catalogRibbon {
    padding: 15px;
    .ribbonCards {
      > div {
        &.tile {
          width: calc(329px - 20%);
          height: 130px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .catalogRibbon {
    .portrait {
      width: 156.7px;
      height: 216px;
    }
    .landscape {
      width: 156.7px;
      height: 113.7px;
    }
  }
}

@media (max-width: 568px) {
  .catalogRibbon {
    .portrait {
      width: 230.7px;
      height: 320px;
    }
    .landscape {
      width: 280px;
      height: 202.7px;
    }
  }
}
