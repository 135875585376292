.fullWindow {
  display: flex;
  padding-top: 80px;
  width: calc(100vw - 65px);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 65px;
  z-index: 499;
  background-color: #f7f7f7;
  overflow-x: auto;
  @media (max-width: 568px) {
    width: 100vw;
    left: 0;
  }
  .close {
    z-index: 500;
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    text-align: center;
  }
  .icon {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
  }
  .message {
    display: 'block';
    margin-top: 5;
    font-size: 14;
  }
}
