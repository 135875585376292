main {
  img {
    width: 100%;
  }
  p {
    font-size: 2.2rem;
    line-height: 3.485rem;
  }
}
.launchPageMain {
  section {
    h2.centered {
      width: 100%;
      text-align: center;
      margin: 100px 0;
      font-size: 2.5em;
    }
  }
}
.player {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 51%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.buttonBlock {
  display: flex;
  width: 100%;
  padding-left: 100px;
  .grayshadow {
    box-shadow: 0 2px 6px #cfcfcf !important;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid transparent;
    &:hover {
      border-color: #cfcfcf;
    }
  }

  .business {
    background-image: linear-gradient(115deg, #fff, #fff);
    margin-right: 0;
    color: black;
    > span {
      color: #333;
    }
  }
  > button {
    height: 50px !important;
    min-width: 250px !important;
    box-shadow: 0 4px 8px 1px #5aaabd !important;
    margin-right: 65px;
    border-radius: 4px !important;
    transition: 0.35s ease-in-out;
    &:hover {
      box-shadow: none !important;
    }
    span {
      font-size: 16px !important;
      font-weight: 500 !important;
      &:hover {
        color: black;
      }
    }
  }
}
.heroSection {
  background-size: cover;
  height: 100vh;
  min-height: 1150px;
  img {
    width: 100%;
    margin-top: 100px;
  }
  > div:first-of-type {
    display: flex;
    padding-top: 150px;
  }
  .heroContent {
    display: flex;
  }
  .heroContentFull {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .designAssetFull {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.heroContent {
  display: flex;
  &.campaigns {
    h1 {
      font-size: 5em;
    }
    .device {
      width: auto;
      height: auto;
    }
    .buttonBlock {
      > button {
        box-shadow: 0 4px 8px 1px #027ab9 !important;
      }
    }
  }
}
.heroCaption {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 6em;
    line-height: 1.25em;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-shadow: 0px 1px 2px #68a9b2;
    margin: 100px;
    p {
      font-size: 3.85rem;
      margin-top: 35px;
      margin-left: 22px;
    }
  }
}
.rowFeatures {
  justify-content: center;
  text-align: center;
  .features {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    h3 {
      font-size: 27px;
      margin-top: 25px;
    }
    p {
      font-size: 2rem;
      margin-top: 25px;
    }
    > div {
      width: calc(100% / 3);
      flex: 1 1 auto;
      padding: 100px 30px;
      justify-content: center;
      text-align: center;
    }
  }
}
.deviceAsset {
  width: 40%;
}
.device {
  width: 315px;
  height: 650px;
}
.playTrigger {
  width: 95px;
  height: 95px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  margin-right: 15px;
  > svg {
    margin-left: 5px !important;
    cursor: pointer;
  }
}
.fullImage {
  width: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
// featureShowcase
.featureShowcase {
  display: flex;
  flex-wrap: wrap;
  padding: 0 100px;
  margin-bottom: 150px;
  .features {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .featureTiles {
    display: flex;
    flex-direction: column;
    width: calc(50% - 100px);
    margin-left: 100px;
    justify-content: center;
  }
  .featureCard {
    display: flex;
    background-color: #fff;
    box-shadow: 0 4px 6px 0 #e0e0e0;
    align-items: center;
    padding: 15px;
    margin-bottom: 1.5em;
    .content {
      margin-left: 20px;
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        margin: 0;
        color: #6f8394;
      }
    }
  }
}
.fullScreen {
  text-align: center;
  img {
    width: 55%;
  }
}
.screens {
  margin-top: 80px;
  .appScreens {
    display: flex;
    .screen {
      width: 50%;
    }
    .gradient {
      background-image: linear-gradient(115deg, #ff5689, #e687ff);
    }
  }
}
.featuresDevice {
  width: 560px;
}
.callToAction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  > div {
    margin-top: 30px;
  }
}
.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 150px;
  margin-top: 50px;
  &.fullIntro {
    margin-bottom: 80px;
    margin-top: 200px;
    padding: 0 50px;
  }
  h1 {
    font-size: 3.675rem;
    margin-bottom: 40px;
    text-align: left;
    margin-top: 28px;
  }
}
.heading {
  font-size: 3.675rem;
  margin-bottom: 40px;
  text-align: center;
}
.grid2x {
  display: flex;
  width: 100%;
  margin-bottom: 200px;
  h2 {
    font-size: 3.675rem;
    margin-bottom: 40px;
    text-align: left;
    margin-top: 28px;
  }
  .contentLeft {
    width: 50%;
    padding: 100px 50px 170px 100px;
    p {
      margin-bottom: 30px;
      color: #6f8394;
    }
  }
  .contentRight {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 4px 8px #ccc;
  }
}

@media (max-width: 1570px) {
  .heroCaption {
    h1 {
      font-size: 5em;
    }
  }
}
@media (max-width: 1373px) {
  .heroCaption {
    h1 {
      font-size: 4em;
    }
  }
}
@media (max-width: 1300px) {
  .heroCaption {
    h1 {
      margin-left: 50px;
    }
  }
  .buttonBlock {
    padding-left: 50px;
  }
  .rowFeatures {
    p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      margin-top: 16px;
    }
  }
  .featureShowcase {
    .features {
      flex-wrap: wrap;
    }
    .featureTiles {
      width: 100%;
      margin-left: 0;
      margin-top: 100px;
      justify-content: center;
    }
    .featureCard {
      .content {
        p {
          font-size: 17px;
        }
      }
    }
  }
  .features {
    .device {
      width: 425px;
    }
  }
  .grid2x {
    .contentLeft {
      padding: 50px;
      padding-bottom: 0;
    }
  }
}
@media (max-width: 1285px) {
  .rowFeatures {
    display: block;
    .features {
      display: block;
      > div {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 1179px) {
  .heroCaption {
    h1 {
      font-size: 3.25em;
    }
    p {
      font-size: 2.35em;
    }
  }
}

@media (max-width: 1070px) {
  .heroSection {
    .heroContent {
      flex-wrap: wrap;
      .heroCaption {
        order: 2;
        width: 80%;
        margin: 50px auto;
      }
      .deviceAsset {
        order: 1;
        width: 100%;
        justify-content: center;
      }
    }
    .device {
      width: 240px;
      height: 450px;
      margin: 0 auto;
      img {
        height: 100%;
      }
    }
  }
  .buttonBlock {
    > button {
      box-shadow: 0 4px 8px 1px #ccc !important;
    }
  }
}

@media (max-width: 991px) {
  .grid2x {
    h2 {
      text-align: center;
    }
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 100px;
    .contentLeft,
    .contentRight {
      width: 100%;
    }
    .contentLeft {
      padding-right: 50px !important;
    }
    .contentRight {
      position: relative;
      padding-bottom: 500px;
    }
    .reOrder1 {
      order: 1;
    }
    .reOrder2 {
      order: 2;
    }
    .noPadding {
      padding-bottom: 0;
    }
  }
  .playTrigger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .fullScreen {
    img {
      width: 100%;
    }
  }
  .intro {
    padding: 0 25px;
  }
  .callToAction {
    padding: 0;
  }
  .buttonBlock {
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    > button {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 850px) {
  .launch-page-main {
    section h2 {
      margin: 50px 0;
      font-size: 1.6em;
    }
    .heroSection {
      > div:first-of-type {
        flex-wrap: wrap;
      }
    }
    .heroCaption {
      width: 100%;
      order: 2;
    }
    .featureShowcase {
      padding: 0px 25px;
    }
    .deviceAsset {
      order: 1;
      width: 100%;
      justify-content: center;
      .device {
        width: 315px;
        height: 650px;
        display: block;
        margin: 0 auto;
        margin-bottom: 60px;
      }
    }
    .heroCaption {
      h1 {
        font-size: 4em;
      }
    }
  }
}

@media (max-width: 799px) {
  .heroSection {
    .heroContent {
      .heroCaption {
        width: 100%;
        h1 {
          margin: 100px 15px;
        }
      }
    }
  }
  .featureShowcase {
    padding: 0 25px;
  }
  .intro {
    margin-bottom: 50px;
    h1 {
      font-size: 3rem;
    }
  }
}

@media (max-width: 567px) {
  .heroSection {
    .heroContent {
      .heroCaption {
        width: 100%;
        h1 {
          font-size: 2.25em;
        }
        p {
          font-size: 3.3rem;
        }
      }
    }
  }
  .grid2x {
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.575rem;
      font-weight: 400;
    }
  }
}
