.videoTile {
  .videoThumbnail {
    position: relative;
    flex: 0 0 auto;
    margin: 10px;
    background-image: linear-gradient(115deg, #ff5689, #e687ff);
    width: 265px;
    height: 150px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: 0.5s cubic-bezier(0.4, 0.4, 0, 1);
    cursor: pointer;
    .thumbnailInner {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      border-radius: 3px;
      background-position: 100%;
      transition: 0.3s scale ease-in-out;
      transition-delay: 0.2s;
    }
    .duration {
      position: absolute;
      bottom: 15px;
      right: 15px;
      color: #fff;
      background: rgba(0, 0, 12, 0.5);
      padding: 0 12px;
      border-radius: 6px;
    }
    .play {
      width: 50px;
      height: 50px;
      background: #fff;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      opacity: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      transform: scale(0);
      transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
      position: relative;
      z-index: 3;
      svg {
        margin-left: 5px;
      }
    }
  }
  h4 {
    margin: 10px 20px 20px;
    font-size: 19px;
    font-weight: 600;
    color: #3c3e41;
  }
  &:hover {
    .thumbnail {
      opacity: 0.3;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    .play {
      transform: scale(1);
      transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      opacity: 1;
    }
    transform: scale(1.1);
  }
}
