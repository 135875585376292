$font-stack: 'Open Sans', sans-serif;

.black {
  font-family: $font-stack;
  font-weight: 900;
  text-transform: uppercase !important;
}
.videoRibbon {
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  width: 100%;
}
.ribbonInner {
  padding: 20px;
  padding-left: 0;
  display: flex;
  flex-direction: row;
}
.ribbonTitleBar {
  display: flex;
  padding: 20px;
  .chip {
    padding: 1px 10px;
    color: #fff;
    background-image: linear-gradient(115deg, #ff5689, #e687ff);
    box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3);
    border-radius: 4px;
    margin-left: 20px;
    font-size: 1.5rem;
    line-height: 30px;
  }
}
