.topNav {
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100vw - 65px);
  padding: 20px 60px 20px 60px;
  position: fixed;
  top: 0;
  left: 65px;
  background: #f9fafc;
  z-index: 102;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    a {
      display: flex;
      align-items: center;
    }
    h2 {
      font-size: 3.14rem;
      font-weight: 400;
      margin-left: 20px;
    }
  }
  .profileActions {
    cursor: pointer;
    margin-right: 0;
    width: auto;
    border-left: 1.5px solid #333;
    padding-left: 25px;
  }
  .accountBar {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .links {
    margin-left: auto;
    a {
      font-weight: 500;
      font-size: 18px;
      margin-right: 25px;
    }
    .colorSelected {
      color: #f26ec5;
      padding-bottom: 8px;
      border-bottom: 2px solid #f26ec5;
      a {
        font-weight: bold;
      }
    }
    .selected {
      color: #fff;
      padding-bottom: 8px;
      border-bottom: 2px solid #fff;
      a {
        font-weight: bold;
      }
    }
  }
  &.showWhite {
    height: 60px;
    max-height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    padding-right: 0;
    padding: 0 70px;
  }
}
.mobileTrigger {
  display: none;
}

@media screen and (max-width: 865px) {
  .topNav {
    padding: 20px;
    .logo {
      h2 {
        font-size: 22px;
      }
      svg {
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .topNav {
    width: 100%;
    left: 0;
    flex-wrap: wrap;
    .accountBar {
      display: none;
      &.show {
        display: block;
        background: #fff;
        margin-top: 20px;
        .links {
          a {
            display: block;
            margin: 25px;
          }
        }
      }
    }
    .profileActions {
      border: none !important;
      padding-bottom: 25px;
      padding-left: 18px;
      > div {
        width: auto !important;
      }
    }
  }
  .mobileTrigger {
    display: flex;
    margin-left: auto;
  }
}
