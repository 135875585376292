.flipContainer {
  perspective: 1000px;
  img {
    width: 100%;
  }
  &.portrait {
    position: relative;
    width: 250.7px;
    height: 350px;
    .front,
    .back {
      width: 250.7px;
      height: 350px;
      box-shadow: 0 1px 5px 0px #717171;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      backface-visibility: hidden;
      position: relative;
      left: 50%;
      @media (max-width: 1100px) {
        width: 225.7px;
        height: 312px;
      }
    }
    .front {
      z-index: 100;
      transform: rotateY(0deg) translateX(-50%);
    }
    .back {
      transform: rotateY(180deg);
      left: 0;
    }
  }
  &.landscape {
    position: relative;
    width: 350px;
    height: 250.7px;
    .front,
    .back {
      position: relative;
      width: 350px;
      height: 250.7px;
      box-shadow: 0 1px 5px 0px #717171;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      backface-visibility: hidden;
      @media (max-width: 1100px) {
        width: 312px;
        height: 225.7px;
      }
    }
    .back {
      transform: rotateX(180deg);
    }
  }
}

/* flip speed goes here */

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  &.flipped {
    transform: rotateY(180deg);
  }
}
