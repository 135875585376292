.bottomGradient {
  #welcomeGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: top;
    transition: 1s cubic bezier(0.29, 0.17, 0, 1);
  }
  position: relative;
  bottom: 0;
  width: 100%;
  height: 300px;
}
