

.cardStackSwiper .swiper {
  width: 240px;
  height: 320px;
}

.cardStackSwiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.cardStackSwiper .swiper-button-next {
  right: -100px;
  width: 20px;
}
.cardStackSwiper .swiper-button-next::after {
  font-size: 28px;
  color: #D1D5DB;
}
.cardStackSwiper .swiper-button-prev {
  left: -100px;
}
.cardStackSwiper .swiper-button-prev::after {
  font-size: 28px;
  color: #D1D5DB;
}


