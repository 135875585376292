$font-stack: 'Open Sans', sans-serif;
.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(48, 57, 63, 0.88);
  z-index: 140;
}
.horizontalVariation {
  bottom: 0 !important;
}
.verticalVariation {
  bottom: 0 !important;
}
.catalogVariation {
  bottom: 0;
  box-shadow: 0 2px 8px 0px #575757;
}
.closePreview {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin-top: 5px;
  }
}
.details {
  margin-bottom: 1rem;
  width: 100%;
  h4 {
    margin-right: auto;
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 1px;
    font-weight: 400;
    &:after {
      content: '';
      width: 30px;
      height: 1px;
      display: block;
      background: #b3b3b3;
      margin-top: 1.5rem;
    }
  }
  p {
    margin-top: 0.75rem;
    line-height: 1.25;
  }
  .premiumTag {
    margin-top: 10px;
    font-size: 1.45rem;
    font-weight: bold;
    color: #333;
    font-family: $font-stack;
    letter-spacing: 0.5px;
    svg {
      margin-right: 10px !important;
    }
  }
  h6 {
    font-size: 24px;
    font-weight: bold;
    span.price {
      display: block;
      text-transform: uppercase !important;
      font-size: 12px;
      font-weight: 200;
      letter-spacing: 1.3px;
      margin-bottom: 10px;
      .discount {
        margin-left: 20px;
        font-weight: bold;
        color: #f270ca;
        padding-bottom: 3px;
        border-bottom: 1px solid #f270ca;
      }
    }
    .fromPrice {
      font-weight: 200;
      font-size: 16px;
      position: relative;
      bottom: 3px;
      left: 22px;
      text-decoration: line-through;
    }
  }
  .postageLabel {
    font-size: 12px;
    letter-spacing: 0.3px;
    span {
      font-size: 23px;
    }
  }
}
.title {
  width: 100%;
  font-family: $font-stack;
}
.description {
  width: 100%;
  font-family: $font-stack;
}
.cardModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-x: auto;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - -65px), -50%);
  z-index: 200;
  background: #fff;
  width: auto;
  min-width: 920px;
  max-width: 1100px;
  img {
    width: 65%;
  }
  .previewContainer {
    display: flex;
    width: 100%;
    &.configurations {
      min-height: 532px;
    }
  }
  .card {
    position: relative;
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
    .portrait {
      width: 390px;
      height: 532px;
    }
    .landscape {
      width: 538px;
      height: 388px;
    }
  }
  .cardDetails,
  .mobileOptions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: $font-stack;
    box-shadow: -10px 0px 2px -10px #aaaaaa;
    padding: 35px;
    background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
    position: relative;
    overflow: hidden;
  }
  .nonMobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 576px) {
      p {
        max-height: 80px;
        overflow-y: scroll;
      }
    }
    @media (max-width: 320px) {
      p {
        max-height: 40px;
        overflow-y: scroll;
        line-height: 1.25;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .action {
    position: initial;
    top: initial;
    right: initial;
    left: initial;
    bottom: initial;
    text-align: center;
    font-weight: bold;
    font-family: 'Dosis', 'Open Sans', sans-serif;
    font-size: 1.35rem;
    color: #fff;
    cursor: pointer;
    width: 135px !important;
    border-radius: 45px;
    height: 30px;
    line-height: 31px;
    svg {
      display: none !important;
    }
    p {
      top: initial;
      font-weight: bold;
    }
    &.cardFlip {
      @media (max-width: 568px) {
        display: none;
      }
      background-color: #fff;
      color: #f56cbf;
      border: 1px solid #f56cbf;
      margin-right: 15px;
      &:hover {
        background-image: linear-gradient(115deg, #ff5689, #e687ff);
        color: #fff;
      }
    }
    &.chooseCard {
      background-image: linear-gradient(115deg, #ff5689, #e687ff);
    }
  }
}
.actionButton {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  transition: background 0.1s ease, height 0.2s ease, width 0.2s ease,
    border-radius 0.2s ease, box-shadow 0.2s ease;
  .action {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.1s ease, height 0.2s ease, width 0.2s ease,
      border-radius 0.2s ease, box-shadow 0.2s ease;
  }
  @media (max-width: 1100px) {
    position: fixed;
  }
}
.options {
  h3 {
    margin: 15px 0;
    padding: 15px;
    background: #fff;
    text-align: center;
  }
  p {
    margin-bottom: 50px;
  }
  .totalPrice {
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    span {
      font-size: 20px;
      font-weight: 200;
      margin-right: 15px;
    }
  }
}
.mobileOptions,
.isMobile {
  display: none;
}
@media (max-width: 1100px) {
  .cardModal {
    width: 800px;
    &.horizontalCard {
      width: 470px;
      flex-wrap: wrap;
      .previewContainer {
        flex-wrap: wrap;
        justify-content: center;
      }
      .options {
        margin-bottom: 0 !important;
      }
      .cardDetails {
        padding-top: 10px;
        .details {
          margin-top: 5px;
          margin-bottom: 15px;
        }
      }
    }
    &.portraitCard {
      width: 678px;
    }
    .cardDetails {
      .details {
        h4 {
          font-size: 19px;
          line-height: 15px;
        }
      }
    }
    .card {
      .portrait {
        width: 43vh;
        height: 60vh;
      }
      .landscape {
        height: 340px;
        width: 470px;
      }
    }
  }
  .options {
    h3 {
      font-size: 15px;
      font-weight: 500;
    }
  }
}
@media (max-width: 970px) {
  .cardModal {
    .options {
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
}
@media (max-width: 850px) {
  .cardModal {
    &.portraitCard {
      width: 80%;
      .previewContainer {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .options {
      width: 100%;
    }
  }
}
@media (max-width: 755px) {
  .cardModal {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 480px) {
  .cardPreview {
    height: 100vh;
  }
  .cardModal {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    left: 0;
    top: 0;
    transform: none;
    &.portraitCard {
      width: 100%;
      height: 100vh;
      .previewContainer {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        height: 100vh;
        flex-wrap: nowrap;
        .card {
          margin: 10px auto;
        }
      }
    }
    &.horizontalCard {
      width: 100%;
      height: 100vh;
      .previewContainer {
        align-items: center;
        flex-direction: column;
        display: flex;
        flex-wrap: nowrap;
        height: 100vh;
        justify-content: space-between;
        .card {
          margin: 10vh auto 15vh auto;
        }
      }
    }
    .cardDetails {
      padding: 16px 35px 8px 35px;
      overflow: auto;
      position: absolute;
      height: auto;
      bottom: 0px;
      height: 44vh;
    }
    .card {
      margin: 0 auto;
      background: none;
      .landscape {
        height: 235px;
        width: 326px;
        margin-bottom: 0;
      }
      @media (max-height: 667px) {
        .portrait {
          height: 305px;
          width: 219px;
        }
      }
      .catalogVariation {
        &.portraitVariation {
          bottom: 245px !important;
        }
        &.horizontalVariation {
          bottom: 160px !important;
        }
      }
    }
  }
  .isMobile {
    padding: 7px 0;
    .actions {
      bottom: 10px;
    }
  }
  .mobileOptions {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 200;
    background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
    width: 100%;
    padding: 35px;
    border-top: 0.5px solid #aeaeae;
    &.openOptions {
      height: 100vh;
    }
    h6 {
      margin-top: 10px;
    }
  }
  .pricing {
    margin-bottom: 16px;
  }
}

@media (max-width: 320px) {
  .cardPreview {
    height: 100vh;
  }
  .cardModal {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    left: 0;
    top: 0;
    transform: none;
    &.portraitCard {
      width: 100%;
      height: 100vh;
      .previewContainer {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        height: 100vh;
        flex-wrap: nowrap;
        .card {
          margin: 10px auto;
        }
      }
    }
    &.horizontalCard {
      width: 100%;
      height: 100vh;
      .previewContainer {
        align-items: center;
        flex-direction: column;
        display: flex;
        flex-wrap: nowrap;
        height: 100vh;
        .card {
          margin: 30px auto;
        }
      }
    }
    .cardDetails {
      padding: 16px 35px 8px 35px;
      overflow: auto;
      position: absolute;
      height: auto;
      bottom: 0px;
      height: 50vh;
    }
    .card {
      margin: 0 auto;
      background: none;
      .landscape {
        height: 33vh;
        width: 50vh;
        margin-bottom: 0;
      }
      @media (max-height: 667px) {
        .portrait {
          height: 305px;
          width: 219px;
        }
      }
      .catalogVariation {
        &.portraitVariation {
          bottom: 245px !important;
        }
        &.horizontalVariation {
          bottom: 160px !important;
        }
      }
    }
  }
  .isMobile {
    padding: 7px 0;
    .actions {
      bottom: 10px;
    }
  }
  .mobileOptions {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 200;
    background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
    width: 100%;
    padding: 35px;
    border-top: 0.5px solid #aeaeae;
    &.openOptions {
      height: 100vh;
    }
    h6 {
      margin-top: 10px;
    }
  }
  .pricing {
    margin-bottom: 16px;
  }
}

@media (min-width: 400px) {
  .cardModal {
    .card {
      &.landscape {
        height: 200px;
        width: 277px;
      }
      &.portrait {
        width: 200px;
        height: 277px;
      }
    }
  }
}
