.catalogWrapper {
  max-width: 1615px;
  margin: 0 auto;
}
.searchHeader {
  position: relative;
}
.catalogSearchBar {
  width: 100%;
}
.catalogSearchField {
  width: 750px;
  margin: 0 auto;
  @media (max-width: 815px) {
    width: 90%;
    border-bottom: 1px solid #f2f2f2;
  }
}
.customEditBtn {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.catalogSearchTags {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-evenly;
  height: 65px;
  align-items: center;
  position: relative;
  top: 10px;
  > div {
    padding-bottom: 0;
  }
  @media (max-width: 400px) {
    top: 65px;
  }
}
// Category View
.catalogGrid {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-left: -0.5em;
  margin-right: -0.5em;
  margin-bottom: 0.5em;
  margin-top: 0;
  > div {
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 1em;
    transition: all 0.3s ease-in;
    border-radius: 4px;
  }
  .portrait {
    min-width: 220.7px;
    height: 320px;
    background-size: cover;
    box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
  }
  .landscape {
    width: 320px;
    height: 220.7px;
    background-size: cover;
    box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
  }
}

.deleteButton {
  margin-left: auto;
  margin-right: 32px;
  @media (max-width: 700px) {
    margin: auto;
  }
}
