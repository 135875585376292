.reasonToSend {
  position: relative;
  max-height: 500px;
  h2 {
    font-weight: 500;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    border-radius: 4px;
    box-shadow: 0 4px 5px 0 #d4d4d4;
    padding: 25px;
  }
  .featureContent {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 1450px;
    width: 80%;
  }
  .reason {
    margin-left: 50px;
    h2 {
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase !important;
    }
    p {
      font-size: 1.75rem;
      letter-spacing: 0.5px;
      line-height: 30px;
      padding: 15px 0 30px 0;
      max-width: 600px;
    }
  }
  .closeCollection {
    position: absolute;
    top: 10px;
    left: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
    min-height: 20px;
    box-shadow: none;
    cursor: pointer;
    span {
      font-size: 1.3rem;
      margin: 2px 0 0 7px;
      font-weight: 600;
    }
  }
}
@media (max-width: 1100px) {
  .reasonToSend {
    .featureContent {
      h2 {
        font-size: 2.285rem;
      }
      p {
        font-size: 1.385rem;
        line-height: 23px;
      }
    }
  }
}
@media (max-width: 980px) {
  .reasonToSend {
    .featureContent {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .reasonToSend {
    max-height: initial;
    .featureContent {
      flex-wrap: wrap;
    }
    .reason {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 568px) {
  .reasonToSend {
    .closeCollection {
      left: initial;
    }
  }
}
